<template>
    <div>
        <b-row>
            <b-col v-if="!data.length" class="d-flex justify-content-center align-items-center" style="min-height: 400px">
                <b-spinner variant="primary" label="Loading..."></b-spinner>
            </b-col>
            <b-col
                v-else
                cols="6"
                v-for="(item, itemKey) in data"
                :key="'document-' + itemKey"
            >
                <b-list-group>
                    <b-list-group-item class="mb-2 d-flex justify-content-between align-items-center" button @click="openPdf(item,itemKey)" :disabled="is_loading" >
                        {{item.name}}
                        <b-spinner variant="primary" label="Spinning" small v-show="itemKey===key && is_loading" ></b-spinner>
                    </b-list-group-item>
                </b-list-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>


// Other

import qs from "qs";
import ParameterService from "@/services/ParameterService";
import StudentDocumentService from "@/services/StudentDocumentService";
import {EventBus} from "@/main";

export default {
    props:{
        studentProgramId:{
            type: Number,
        },
        studentNumber:{
            type: String,
        }
    },
    data() {
        return {
            data:[],
            is_loading: false,
            key:null
        }
    },
    created() {
        this.getParameterItem();
    },
    methods: {
        getParameterItem(){
            const config = {
                params: {
                    filter: {
                        parameter_code:'student_verification_letter_types'
                    },
                    sort: 'id',
                    limit: -1,
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };
            ParameterService.getAllItems(config)
                .then((response) => {
                    this.data= response.data.data;
                })
                .catch((error) => {
                    this.showErrors(error)
                })
        },
       async openPdf({code,name},key) {
            this.key = key;
            this.is_loading=true
            const data = {
                verification_letter_type:code
            }
            try {
                StudentDocumentService.studentVerificationLetterPdf(this.studentProgramId,data).then(response=>{
                    const file_name=this.studentNumber+' _ '+name+'.pdf'
                    EventBus.$emit('pdfViewerModalShow', {pdfFileData: response.data, pdfFileName:file_name});
                }).catch(error=>{
                    this.showErrors(error,null,true)
                });

            }catch (error) {
                this.showErrors(error)
            }finally{
               this.is_loading=false
           }

       }

    }
}
</script>

